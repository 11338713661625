import React from "react";
import { Grid } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import FacultyCard from "../../muiCommonComponent/facultyCards";

const FacultyList=()=>{
    const facultyList=[
        {img:'/assets/img/SOPS/faculty/yogender_singh.jpg',name:'Prof. (Dr.) Yogendra Singh ',position:'Dean Academics & Director, IQAC',email:'deanacademics@mvn.edu.in, director.iqac@mvn.edu.in', gs: "https://scholar.google.com/citations?user=8TCZslQAAAAJ&hl=en",
        linkedin: " https://www.linkedin.com/in/prof-dr-yogendra-singh-20102445/",
        orcid: "https://orcid.org/0000-0002-6117-1941",
        rg: "https://www.researchgate.net/profile/Yogendra-Singh-56",
        website: "#",
        aoi: "Pharmacognosy & Phytochemistry",
        education: "Ph.D. in Pharmaceutical Sciences",
        re: "3",
        te: "16",
        iece: "3",
        articles:
          "<li> Y Singh, SK Paswan, R Kumar, MK Otia, S Acharya, D Kumar, “ Plant & Its Derivative Shows Therapeutic Activity on Neuroprotective Effect,” Journal for Research in Applied Sciences and Biotechnology, 1(2), 10-24, 2023., Y Singh, KK Das, MT Thelly, B Kumar, A Roy,</li><li> “ Phytochemical Study And In Vitro Anti-Diabetic Activity Of Eucalyptus And Lemon Oils,”  European Chemical Bulletin 12 (6), 2105 – 2116, 2023., R Garg, M Piplani, Y Singh, P Bhateja, R Rana,</li><li> “ An Overview of Integrated Risk Factors with Prevention and Prevalence of Asthma at the Global Level,”  Current Traditional Medicine 10 (4), 71-81, 2024</li>",
        books:
          "<li>Dr. Sadhu Venkateswara Rao, Prof.(Dr.) Yogendra Singh, Prof. (Dr.) Chennu MM Prasada Rao and Dr. Jiwan Premchand Lavande. Pharmaceutical Technology, ISBN: 9789395468671, AGPH Books (Academic Guru Publishing House), 2022.,</li><li> Prof.(Dr.) Yogendra Singh, Mr. Pankaj Bhateja, Dr. Rajesh Kumar, Ms. Neeru Malik. A Comprehensive Book of Pharmacy, ISBN: 9769391385361, Corvette Press, 2023,</li><li> Prof. Brijesh Kumar, Dr. Rajesh Kumar, Dr. Vijay Kumar Bansal, Prof.(Dr.) Yogendra Singh. Medicinal Chemistry,  IBS Publication, 2023.,</li><li> Prof. Brijesh Kumar, Dr. Rajesh Kumar, Dr. Vijay Kumar Bansal, Prof.(Dr.) Yogendra Singh. Pharmaceutical Inorganic Chemistry, IBS Publication, 2023</li>",
        chapters:
          "Rahesh Kumar, Ygendra Singh, Mona Piplani, Pankaj Bhateja, Ruchika Garg & Brijesh Kumar  “ The Chemistry and Pharmacological Study of Copaifera spp. Oils,”  The Chemistry Inside Spices & Herbs, vol. 4,  78-116, 2024,  Bentham Sciences Publishers.",
        patents:
          "<li>Model for Detection of Security Attack in IoT Enabled Environment”, Inventors:  Abdul Hannan Abdul Mannan Shaikh, Dr V. Christo Selvan, Dr. B. V. V. Siva Prasad, Dr. Namita Mishra, Dr. Pratibha Teotia, Dr. Priyanka Jain, Dr. Samala Nagaraj, Dr. Samriti Mahajan, Dr. Shashi Prabha Nagendra, Dr. Sita Rani, Dr. Yogendra Singh, Publication Date:  28 Sept. 2022, Application No.:  2022/07611 (South Africa Patent),</li><li> Incubator for Controlling Temperature & Humidity for the Cultivation and Propagation of Plant Cell Culture”, Inventors: Prof.(Dr) Bhupendra Kumawat, Dr. Yogendra Singh, Bharat Goswami, Arjun Kasnia, Manoj Khardia, Publication Date: 5 July 2023, Application No.: 389630-001.</li><li> Artificial Intelligence Based Muffle Furnace”, Inventors: Prof.(Dr) Yogendra Singh, Smita Acharya, Ms Rekha Rani, Publication Date: 29 March 2024, Application No.:  404930-001</li>",
        membership:
          "<li>Registered Pharmacist of Rajasthan Pharmacy Council. Reg. No. 18658,</li><li>  Life member of “Association of Pharmacy Teachers of India (APTI)”,</li><li> Life member of “Association of Pharmacy Professionals (APP)”,</li><li> Life member of “Indian Pharmacy Graduates’ Association (IPGA)”</li>",
        aa1: "“Prof. M.L. Schroff Pharma Recognition Award” on the Occasion of Teachers’ Day on 05th Sept 2022 by Team Pharmalok.",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        /*{img:'/assets/img/SOPS/tarun.png',name:'Prof (Dr.) Tarun Virmani',position:'Professor & Dean',email:'tarun.virmani@mvn.edu.in',
        gs: "https://scholar.google.com/citations?hl=en&user=QRqpEZIAAAAJ",
        linkedin: " https://in.linkedin.com/in/dr-tarun-virmani-71a798117",
        orcid: "https://orcid.org/0000-0002-1615-5213",
        rg: "https://www.researchgate.net/profile/Tarun-Virmani",
        website: "#",
        aoi: "Product Development, Nanotechnology",
        education: "PhD (Pharmaceutical Sciences)",
        re: "N/A",
        te: "18",
        iece: "N/A",
        articles:
          "<li> Virmani T, Kumar G, Sharma A, Pathak K, Akhtar MS, Afzal O, Altamimi AS. Amelioration of cancer employing chitosan, its derivatives, and chitosan-based nanoparticles: Recent updates. Polymers. 2023 Jul 1;15(13):2928.</li><li>Virmani T, Kumar G, Virmani R, Sharma A, Pathak K. Nanocarrier-based approaches to combat chronic obstructive pulmonary disease. Nanomedicine. 2022 Oct;17(24):1833-54.</li><li> Virmani T, Gupta J. Pharmaceutical application of microspheres: an approach for the treatment of various diseases. Int J Pharm Sci Res. 2017;8(8):3253-60.</li>",
        books:
          "<li> A Text book of Industrial Pharmacy-I for B.Pharm 5th semester as per PCI syllabus by Tarun Virmani & Girish Mittal by Dr. P.K. Education, Bilaspur.</li><li>A text book of Pharmaceutics-I for B.Pharm 1st Semester as per PCI syllabus by Tarun Virmani & Girish Mittal by Dr. P.K. Education, Bilaspur. </li><li> A text Book of Physical Pharmaceutics-II (Theory) for B.Pharm IVth semester as per PCI syllabus, Dr. Tarun Virmani, Reshu Virmani and Girish Mittal by Nirali Prakashan, Pune</li>",
        chapters:
          "<li>Virmani T, Chhabra V, Kumar G, Sharma A, Pathak K. Impact of nonconventional solvents on environment. In: Mukhopadhyay C, Banerjee B, editors. Organic Synthesis, Natural Products Isolation, Drug Design, Industry and the Environment [Internet]. De Gruyter; 2023. p. 265–84. </li><li>Virmani, T., Kumar, G., Virmani, R., Sharma, A., & Pathak, K. (2023). Xanthan Gum-Based Drug Delivery Systems for Respiratory Diseases. In Natural Polymeric Materials based Drug Delivery Systems in Lung Diseases (pp. 279-295). Singapore: Springer Nature Singapore. </li><li> A Text book of Industrial Pharmacy-I for B.Pharm 5th semester as per PCI syllabus by Tarun Virmani & Girish Mittal by Dr. P.K. Education, Bilaspur.</li>",
        patents:
          "<li>Solar assisted iot based automatic vertical medicinal plant cultivation of critically endangered plant nardostachys jatamansi patent number 2021103371</li><li>A solar assisted climatic reader farming robotic system and its working method thereof patent number 2021103075</li><li>Iot based sustainable incinerator for biomedical and pharmaceutical waste australian patent number - 2021102310- granted</li>",
        membership:
          "<li>APP - APP/HR/LM-033/21</li><li>APTI - HA/LM-349</li><li>IPGA – LM 2917</li>",
        aa1: "Local Organizing Member in 58th, 65th, 69th  Indian Pharmaceutical Congress in year 2008, 2013 &  2017",
        aa2: "Got financial assistance of Rs. 50,000/- for celebration of National Technology Day, 2023 from DST Panchkula.",
        aa3: "Won a cash prize of Rs. 10,000 for writing the best essay on the topic, Role of I.T. in Pharma Industry organized by Indian Pharmaceutical Congress's in 2001.",
        aa4: "",
        aa5: "",
        aa6: "",
        },*/
        /*
        {img:'/assets/img/SOPS/ashutoshupadhayay.jpg',name:'Dr. Ashutosh Upadhayay',position:'Professor & Dean',email:'dr.ashutoshupadhayay@mvn.edu.in',
          gs: "https://scholar.googleh.com/citations?user=h3LRiD0AAAAJ&hl=en&authuser=1",
          linkedin: "",
          orcid: "https://orcid.org/0009-0002-8848-2149",
          rg: "https://www.researchgate.net/profile/dr.ashutoshupadhayay",
          website: "#",
          aoi: "Clinical Research, Antidiabetic, Anti-hyperlipidemic, Toxicology",
          education: "PhD (Pharmacology)",
          re: "1",
          te: "18",
          iece: "2 Month",
          articles:
            "<li>1.	Correlation between demographic characteristics and degree of illness among asthmatic patients in the panchkula region, European Chemical Bulletin, 2023 12 (special issue 5), 4868-4874. </li>2.	Clinical trials in Pharmacy: Methodology and Ethics considerations, European Chemical Bulletin, 2023 (special issue 9)2971-2983 <li> </li>3.	A review on comparison of allopathic medicines to other drug therapies in the management of asthama, Infect Disord Drug targets, 24(2);2024, Article ID: e201023222496 <li> </li><li> 4.	The influence of Phytoconstituents for the management of antipsoriatic activity in various animal models, Anti-inflamma. & Anti-allerg. Agents in Med. Chem ,07;2024 DOI: 10.2174/0118715230320581240711063558 </li>",
          books:
            "<li>1.	A Text book of Pharmaceutical Jurisprudence, Nitya Publication, Bhopal, MP, India. ISBN: 9789394894495  </li><li>2.	A practical book of Pharmacology- II, TDC Publication, Gurugram, Haryana, India. ISBN: 9789391864705  </li>",
          chapters:
            "<li>NA</li>",
          patents:
            "<li>Polyherbal formulation for wound healing and a process for the preparation there of Appl. No. 202311004263A, Issue No. 4/2023, publication date 27/01/2023</li>",
          membership:
            "<li>i.	Life Member, Association of the Pharmaceutical Teachers of India (APTI). </li><li>ii.	Registered Pharmacist of Rajasthan pharmacy council. </li><li>iii.	Life member, Indian Pharmacy Graduate Association (IPGA) </li>",
          aa1: "1.	Awarded by APP Best Teacher Award 2014 in National seminar(FPER: GC2014) November 16, 2014 at Alwar Pharmacy College, Alwar ",
          aa2: "2.	Awarded by Best Teacher Award 2022 for outstanding achievement in NIMS University.",
          aa3: "3.	Conducted Pre conference workshop on ‘Hands on training on ADR reporting’ organized by NIMS institute of pharmacy, NIMS university, Jaipur on dated 25th November 2022 as a registration incharge.",
          aa4: "4.	Conducted International conference on ‘Fostering high quality clinical research for a healthier world” organised by NIMS institute of pharmacy, NIMS university, Jaipur on dated 26 november 2022 registration incharge. ",
          aa5: "5.	Conducted Workshop-cum hands on practice on “Artificial intelligence in new molecular discoveries” as a organizing secretary (A part of social scientific responsibility activity of SERB project) organised by center of inter-dicilplinary biomedical research (CIBR), Adesh university, bathinda, punjab on dated 27-29 april 2023.",
          aa6: "",
          }, */

          {img:'/assets/img/SOPS/ashutoshupadhayay.jpg',name:'Dr. Ashutosh Upadhayay',position:'Professor & Dean',email:'dr.ashutoshupadhayay@mvn.edu.in',
            gs: "https://scholar.googleh.com/citations?user=h3LRiD0AAAAJ&hl=en&authuser=1 ",
            linkedin: "",
            orcid: "https://orcid.org/0009-0002-8848-2149",
            rg: "https://www.researchgate.net/profile/Ashutosh-Upadhayay-2 ",
            website: "#",
            aoi: "Clinical Research, Antidiabetic, Anti-hyperlipidemic, Toxicology",
            education: "PhD (Pharmacology)",
            re: "1",
            te: "18",
            iece: ".02",
            articles:
              "<li>1.	Correlation between demographic characteristics and degree of illness among asthmatic patients in the panchkula region, European Chemical Bulletin, 2023 12 (special issue 5), 4868-4874.  </li> <li>2.	Clinical trials in Pharmacy: Methodology and Ethics considerations, European Chemical Bulletin, 2023 (special issue 9)2971-2983  </li> <li>3.	A review on comparison of allopathic medicines to other drug therapies in the management of asthama, Infect Disord Drug targets, 24(2);2024, Article ID: e201023222496  </li><li>4.	The influence of Phytoconstituents for the management of antipsoriatic activity in various animal models, Anti-inflamma. & Anti-allerg. Agents in Med. Chem ,07;2024 DOI: 10.2174/0118715230320581240711063558  </li>",
            books:
              "<li>1.	A Text book of Pharmaceutical Jurisprudence, Nitya Publication, Bhopal, MP, India. ISBN: 9789394894495 </li><li>2.	A practical book of Pharmacology- II, TDC Publication, Gurugram, Haryana, India. ISBN: 9789391864705</li>",
            chapters:
              "<li>NA</li>",
            patents:
              "<li>Polyherbal formulation for wound healing and a process for the preparation there of Appl. No. 202311004263A, Issue No. 4/2023, publication date 27/01/2023</li>",
            membership:
              "<li> i.	Life Member, Association of the Pharmaceutical Teachers of India (APTI). </li><li>ii.	Registered Pharmacist of Rajasthan pharmacy council. </li><li> iii.	Life member, Indian Pharmacy Graduate Association (IPGA) </li>",
            aa1: "1.	Awarded by APP Best Teacher Award 2014 in National seminar (FPER: GC2014) November 16, 2014 at Alwar Pharmacy College, Alwar ",
            aa2: "2.	Awarded by Best Teacher Award 2022 for outstanding achievement in NIMS University",
            aa3: "3.	Conducted Pre conference workshop on ‘Hands on training on ADR reporting’ organized by NIMS institute of pharmacy, NIMS university, Jaipur on dated 25th November 2022 as a registration in-charge. ",
            aa4: "4.	Conducted International conference on ‘Fostering high quality clinical research for a healthier world” organised by NIMS institute of pharmacy, NIMS university, Jaipur on dated 26 november 2022 registration in-charge.  ",
            aa5: "5.	Conducted Workshop-cum hands on practice on “Artificial intelligence in new molecular discoveries” as a organizing secretary (A part of social scientific responsibility activity of SERB project) organised by center of inter-dicilplinary biomedical research (CIBR), Adesh university, bathinda, punjab on dated 27-29 april 2023.",
            aa6: "6.	Conducted 3rd International Conference on “Recent Advancements and Innovations in Healthcare.” as a organizing secretary & Registration Incharge (IPGA), at MVN University, Palwal, Haryana on dated 15th  Nov. 2024.",
            about:  "<p>Prof (Dr.) Ashutosh Upadhayay did his Bachelor In Pharmacy from University of Rajasthan in 2005, Post graduation in Pharmacology from The Tamilnadu Dr. MGR Medical University, Chennai in 2008 and Doctorate from NIMS University, Rajasthan in 2017. He served as various academic positions at the lavel of Lecturer, Assistant Professor, Associate Professor and Professor in different colleges. Prof. Upadhayay has been associated with Quality Pharmacy Education as an academician since 20 yrs. Presently, he is serving as a Professor & Dean, MVN University, Palwal, Haryana.</p><p>His research areas of interest are Modern methods of extraction techniques, Isolation and characterization of bioactive compound (s), Clinical research, Toxicological studies, Rational Use of drugs, Hepatotoxicity, Diabetes, Hyperlipidemic, behavioral studies and herbal drug technology.</p><p>He is recipient of prestigious Best Teacher Award in 2014, outstanding achievement in teaching Award in 2022, Best Researcher Award in 2023</p><p>He has guided various students for their M. Pharm and Ph.D. research studies. He has published</p><p>More than 50 research papers in International and National journals. He also published various books of pharmacy domain. He has also contributed in patents application and grants and has presented several research papers in scientific conferences. He has also organized various national and international conferences, Faculty Development programmes, seminars and workshops.</p><p>He is a life member of several associations like IPGA, APTI etc.</p>",
            },


        {img:'/assets/img/SOPS/faculty/jayamanti_pandit.jpg',name:'Dr. Jayamanti Pandit',position:'Associate Professor',email:' drjayamanti.pandit@mvn.edu.in',gs: "https://scholar.google.com/citations?user=K9IhfIQAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/jayamanti-pandit-32154022/",
        orcid: "https://orcid.org/0000-0001-5816-5797      ",
        rg: "https://www.researchgate.net/profile/Jayamanti-Pandit",
        website: "#",
        aoi: "Nanotechnology, Ocular delivery, Transdermal delivery      ",
        education: "PhD (Pharmaceutics)      ",
        re: "9",
        te: "8",
        iece: "N/A",
        articles:
          "<li>Pandit, J., Chaudhary, N., Emad, N.A., Ahmad, S., Solanki, P., Aqil, M., Sultana, Y. and Solanki, P., 2023. Fenofibrate loaded nanofibers based thermo-responsive gel for ocular delivery: Formulation development, characterization and in vitro toxicity study. Journal of Drug Delivery Science and Technology, 89, p.104935. <li><li>Pandit, J., Sultana, Y. and Aqil, M., 2021. Chitosan coated nanoparticles for efficient delivery of bevacizumab in the posterior ocular tissues via subconjunctival administration. Carbohydrate Polymers, 267, p.118217..</li><li>Pandit, J., Sultana, Y. and Aqil, M., 2017. Chitosan-coated PLGA nanoparticles of bevacizumab as novel drug delivery to target retina: optimization, characterization, and in vitro toxicity evaluation. Artificial cells, nanomedicine, and biotechnology, 45(7), pp.1397-1407.</li><li>Pandit, J., Sultana, Y. and Aqil, M., 2021. Chitosan coated nanoparticles for efficient delivery of bevacizumab in the posterior ocular tissues via subconjunctival administration. Carbohydrate Polymers, 267, p.118217..</li>",
        books:
          "N/A",
        chapters:
          "<li>https://scholar.google.com/scholar?oi=bibs&cluster=1893559369256021131&btnI=1&hl=en</li><li>Pandit, Jayamanti*, Md Sabir Alam, Md Noushad Javed, Aafrin Waziri, and Syed Sarim Imam. \"Emerging Roles of Carbon Nanohorns As Sustainable Nanomaterials in Sensor, Catalyst, and Biomedical Applications.\" In Handbook of Green and Sustainable Nanotechnology: Fundamentals, Developments and Applications, pp. 1-27. Cham: Springer International Publishing, 2023.</li><li>Jahangir, Mohammed Asadullah, Syed Sarim Imam, and Jayamanti Pandit*. \"Impact and Role of Stability Studies in Parenteral Product Development.\" In Micro-and Nanotechnologies-Based Product Development, pp. 225-234. CRC Press, 2021</li><li>Pandit, Jayamanti, Md Sabir Alam, Jamilur R. Ansari, Monisha Singhal, Nidhi Gupta, Aafrin Waziri, Kajal Sharma, and Faheem Hyder Pottoo. \"Multifaced applications of nanoparticles in biological science.\" In Nanomaterials in the Battle Against Pathogens and Disease Vectors, pp. 17-50. CRC Press, 2022.</li>        ",
        patents:
          "N/A",
        membership:
          "<li>Life Member, Association of the Pharmaceutical Teachers of India (APTI). </li><li>Registered Pharmacist of M.P state.</li><li> Registered Patent Agent, India</li><li>Life member, Indian Pharmacy Graduate Association (IPGA)</li>        ",
        aa1: "Awarded certificate of appreciation by Jamia Hamdard for high impact factor publications in the year 2018, 2022, and 2024.      ",
        aa2: "Junior Research Fellowship (UGC) for Post-Graduation at Dr. H. S. Gour University, Sagar, for the period 2003-2005. ",
        aa3: "CSIR-SRF for PhD at Jamia Hamdard, Delhi for the period 1st April 2013- 31st March 2017.      ",
        aa4: "Awardees of prestigious “Women-Scientist Scholarship Scheme WOS-C (KIRAN IPR) in Intellectual Property Rights granted by Patent Facilitating Centre, Technology Information Forecasting Assessment Council (TIFAC), Department of Science and Technology, Govt. of India.      ",
        aa5: "Awarded research fellowship as Research Associate (ICMR) in 2020.      ",
        aa6: "Qualified Patent agent examination in first attempt in 2022 and registered as a Patent agent.      ",},
        {img:'/assets/img/SOPS/faculty/geeta_mahlawat.jpg',name:'Dr. Geeta Mahlawat',position:'Associate Professor',email:'geeta.mahalawat@mvn.edu.in', gs: "https://scholar.google.com/citations?user=K9IhfIQAAAAJ&hl=en",
        linkedin: "www.linkedin.com/in/geeta-tanwar-46095b204",
        orcid: "https://orcid.org/0000-0003-4312-0056",
        rg: "https://www.researchgate.net/profile/Geeta-Mahlawat",
        website: "#",
        aoi: "Pharmaceutics",
        education: "PhD (Pharmaceutics)      ",
        re: "1",
        te: "10",
        iece: "N/A",
        articles:
          "<li>Mahlawat, G., Virman,i T., Virman,i R., Singh S., 2018. Comparative analysis of ophthalmic gel forming polymers on the drug release of timolol maleate. International Research Journal of Pharmacy,pp. 1-7 <li><li>Mahlawat, G., Virmani, T. and Arif, M., 2022. Implementation Of Box Behnken Design To Fabricate The Formulation, Optimization And Dissolution Of Blueberry Nanosuspension. Journal of  Pharmaceutical Negative Results, pp.595-605.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "<li>Member of APTI</li><li> Member of IPGA</li>        ",
        aa1: "Got Best dedicated faculty award in BR College of Pharmacy in Annual Programme",
        aa2: "Paper presented at 4th International conference on Digital Healthcare in post COVID Era in association with Department of Science &Technology on the topic “Nanosuspension as Drug Delivery System for Improvement of Bioavailability of herbal Bioactive”",
        aa3: "Paper presented at National Conference on Intellectual property rights of pharmaceuticals 2.0. on the topic “Enhancement of therapeutic action of Antihyperlipidemic drugs by using a novel nanosuspension-based approach.  ",
        aa4: "Got first prize in poster presentation STCP National conference held in Alwar Pharmacy College. ",
        aa5: " ",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/girish_kumar.jpg',name:'Dr. Girish Kumar',position:'Associate Professor',email:'girish.kumar@mvn.edu.in',gs: "https://scholar.google.com/citations?user=pprjmGkAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/dr-girish-kumar-ab7805165",
        orcid: "https://orcid.org/0000-0002-2917-3277",
        rg: "https://www.researchgate.net/profile/Girish-Kumar-59",
        website: "#",
        aoi: " Nanoformulations",
        education: "M. Pharm, PhD ",
        re: "N/A",
        te: "10",
        iece: "N/A",
        articles:
          "<li>. G. Kumar, T. Virmani, K. Pathak, and A. Alhalmi, “A Revolutionary Blueprint for Mitigation of Hypertension via Nanoemulsion,” BioMed Research International, vol. 2022, p. e4109874, Apr. 2022, doi: 10.1155/2022/4109874.<li><li>G. Kumar, T. Virmani, K. Pathak, O. A. Kamaly, and A. Saleh, “Central Composite Design Implemented AzilsartanMedoxomil Loaded Nanoemulsion to Improve Its Aqueous Solubility and Intestinal Permeability: In Vitro and Ex Vivo Evaluation,” Pharmaceuticals, vol. 15, no. 11, Art. no. 11, Nov. 2022, doi: 10.3390/ph15111343.</li><li>G. Kumar, T. Virmani, A. Sharma, and K. Pathak, “Codelivery of Phytochemicals with Conventional Anticancer Drugs in Form of Nanocarriers,” Pharmaceutics, vol. 15, no. 3, Art. no. 3, Mar. 2023, doi: 10.3390/pharmaceutics15030889.</li>",
        books:
          "<li>A Practical Book of Physical Pharmaceutics-II (Theory) for B.PharmIVth semester as per PCI syllabus, Dr. TarunVirmani, ReshuVirmani and Girish Mittal by NiraliPrakashan, Pune.</li><li>A Text book of Industrial Pharmacy-I for B.Pharm 5th semester as per PCI syllabus by TarunVirmani&Girish Mittal by Dr. P.K. Education, Bilaspur.</li><li>A text Book of Physical Pharmaceutics-II (Theory) for B.PharmIVth semester as per PCI syllabus, Dr. TarunVirmani, ReshuVirmani and Girish Mittal by NiraliPrakashan, Pune. </li>",
        chapters:
          "<li>T. Virmani, G. Kumar, A. Sharma, and K. Pathak, “An overview of ocular drug delivery systems—conventional and novel drug delivery systems,” in Nanotechnology in Ophthalmology, Elsevier, 2023, pp. 23–48. doi: 10.1016/B978-0-443-15264-1.00007-5.</li><li>S. Gopika, G. Kumar, R. Virmani, A. Sharma, A. Sharma, and T. Virmani, “Advancement towards Brain-Targeted MNPs in Neurological Disorders,” in Metallic Nanoparticles for Health and the Environment, 1st ed., Boca Raton: CRC Press, 2023, pp. 209–237. doi: 10.1201/9781003317319-9.</li><li>G. Kumar, T. Virmani, A. Sharma, R. Virmani, and K. Pathak, “Chapter 14 - Nanoparticulate drug delivery systems for colonic disorders,” in Advanced Drug Delivery Systems for Colonic Disorders, H. Dureja, R. Loebenberg, S. K. Singh, M. Dhanasekaran, and K. Dua, Eds., Academic Press, 2024, pp. 317–344. doi: 10.1016/B978-0-443-14044-0.00009-0.</li>",
        patents:
          "N/A",
        membership:
          "<li>IPGA </li><li> APP</li><li> APTI</li>      ",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: " ",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/praveen_arora.jpg',name:'Dr. Praveen Kumar Arora',position:'Associate Professor',email:'praveen.kumar@mvn.edu.in ', gs: "#",
        linkedin: " http://www.linkedin.com/in/dr-praveen-arora-789b0422",
        orcid: "https://orcid.org/0000-0003-3643-3441?lang=en",
        rg: "#",
        website: "#",
        aoi: "Drug designing, Virtual Screening, Molecular docking, Synthesis and biological evaluation of heterocyclic compounds",
        education: "PhD (Pharmacy)",
        re: "5",
        te: "17",
        iece: "4",
        articles:
          "<li>Docking based virtual screening of Lipinski compliant 2-arylquinazolinon-4-one derivatives: A momentum to the discovery of novel EGFR inhibitors. International Journal of Pharmaceutical sciences and Research, 2021, Vol. 12(3): 1699-1712</li><li>Synthesis, In vitro Cytotoxicity, Molecular docking of Few Quinazolinone Incorporated Naphthyl Chalcones: As Potential Dual Targeting Anticancer Agents to Treat Lung Cancer and Colorectal Cancer. Oriental Journal of Chemistry, 2023, Vol. 39(2): 231-245</li><li>Synthesis, In Vitro Cytotoxicity And In Silico Investigations of Quinazolinone Integrated Chalcones: As Novel Potential Dual Targeting Anticancer Agents to Treat Lung Cancer And Colorectal Cancer. Rasayan Journal of chemistry, 2023,Vol.16(3):1104-1115</li>",
        books:
          "<li>Name Reactions & Organic Drugs Synthesis (The mechanistic approach)”  ISBN: 978-93-80316-00-0  Publisher- AHUJA PUBLISHING HOUSE, NEW-DELHI</li>",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "GATE 2001 Qualified conducted by IIT-Kanpur",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/ashish.jpg',name:'Aashish Bhardwaj',position:'Assistant Professor',email:'ashish.bhardwaj@mvn.edu.in',
        gs: "https://scholar.google.com/citations?view_op=list_works&hl=en&user=dlRiYF0AAAAJ      ",
        linkedin: "www.linkedin.com/in/bhardwajaashish      ",
        orcid: "https://orcid.org/0000-0001-9878-1877      ",
        rg: "https://www.researchgate.net/profile/Aashish-Bhardwaj-6      ",
        website: "#",
        aoi: "Pharmacology      ",
        education: "M. Pharmacy      ",
        re: "N/A",
        te: "2",
        iece: "N/A",
        articles:
          "Bharadwaj a, sharma a, singh t, pathak d, virmani t, kumar g attenuation of strychnine-induced epilepsy employing amaranthusviridis l. Leaves extract in experimental rats        ",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",
        },
        {img:'/assets/img/SOPS/faculty/jyoti.jpg',name:'Jyoti',position:'Assistant Professor',email:'jyoti.sops@mvn.edu.in',gs: "#",
        linkedin: " https://www.linkedin.com/in/jyoti-yadav-6038771b8",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: " Pharmaceutical Chemistry (Instrumentation)",
        education: "M. Pharma (Pharmaceutical Chemistry)",
        re: "1",
        te: "0.2",
        iece: "2",
        articles:
          "<li>Docking based virtual screening of Lipinski compliant 2-arylquinazolinon-4-one derivatives: A momentum to the discovery of novel EGFR inhibitors. International Journal of Pharmaceutical sciences and Research, 2021, Vol. 12(3): 1699-1712</li><li>Synthesis, In vitro Cytotoxicity, Molecular docking of Few Quinazolinone Incorporated Naphthyl Chalcones: As Potential Dual Targeting Anticancer Agents to Treat Lung Cancer and Colorectal Cancer. Oriental Journal of Chemistry, 2023, Vol. 39(2): 231-245</li><li>Synthesis, In Vitro Cytotoxicity And In Silico Investigations of Quinazolinone Integrated Chalcones: As Novel Potential Dual Targeting Anticancer Agents to Treat Lung Cancer And Colorectal Cancer. Rasayan Journal of chemistry, 2023,Vol.16(3):1104-1115</li>",
        books:
          "<li>Name Reactions & Organic Drugs Synthesis (The mechanistic approach)”  ISBN: 978-93-80316-00-0  Publisher- AHUJA PUBLISHING HOUSE, NEW-DELHI</li>",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "<li>Life membership of Association of pharmaceutical teacher of India.</li><li> Life Membership of IPGA</li>",
        aa1: "Certification In Handling of UV visible spectrophotometer Channel, UHPLC, FTIR Conducted by Gurugram University Gurugram Haryana India",
        aa2: "Certification in drug repurposing: A new ray to overcome covid 19 disaster conducted at KR Mangalam University, Gurugram, Haryana",
        aa3: "Certification of pharmacovigilance in healthcare system conducted by ISF College of pharmacy, Moga, Punjab",
        aa4: "Certification in National Workshop on pharmacovigilance conducted by Gurugram University Gurugram Haryana India",
        aa5: "Certification of scientific posterior on medicinal plants of Ganga River basin and their therapeutic importance in Indian system of medicine held at Gurukul Campus Uttarakhand Ayurveda University, Haridwar.",
        aa6: "Certification on Poster presentation on Understanding the role of digital health care in India in National Conference conducted by Sushant University, Gurugram India",},
        {img:'/assets/img/SOPS/faculty/joyson_paul.jpg',name:'Joyson Paul',position:'Assistant Professor',email:'joyson.paul@mvn.edu.in',gs: "https://scholar.google.com/citations?user=N0c5e4cAAAAJ&hl=hi",
        linkedin: "https://www.linkedin.com/in/joyson-paul-bychrist/",
        orcid: "https://orcid.org/0000-0002-7804-9854",
        rg: "http://www.researchgate.net/profile/",
        website: "http://www.youtube.com/@joysonclasses",
        aoi: "Pharmaceutical Analysis",
        education: "M. Pharmacy",
        re: "1",
        te: "2",
        iece: "0.5",
        articles:
          "<li>http://dx.doi.org/10.2174/1573412919666221123110420</li><li>Elagolix Sodium: Novel GnRH Antagonist for the Treatment of Endometriosis Joyson Paul, Rohit Bhatia, Volume 2, Issue 1,2021,Journal of Clinical Anesthesiology  DOI:10.52916/jcar214007</li><li>Simultaneous estimation of Luliconazole and Clobetasol Propionate by RP-HPLC. Volume 12, Issue 8, 2021, Journal of Chromatography and Separation Techniques.  </li><li>Overview of Chemistry and Therapeutic Potential of Non-Nitrogen Heterocyclics as Anticonvulsant Agent. Rohit Pal, Karanvir Singh, Joyson Paul , Shah Alam Khan , Mohd. Javed Naim and Md Jawaid Akhtar, (Current Neuropharmacology)  Volume 20,Issue 12, 2022 Bentham Science Publishers I.F  7.7 DOI:10.2174/1570159X19666210803144815</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "<li>Association of Pharmaceutical Teachers of India</li>",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/mansi_gaba.jpg',name:'Mansi Gaba',position:'Assistant Professor',email:'mansi.gaba@mvn.edu.in', gs: "#",
        linkedin: "https://www.linkedin.com/in/mansi-gaba-4846731b6/",
        orcid: "https://orcid.org/0009-0008-2257-5864",
        rg: "https://www.researchgate.net/profile/Mansi-Gaba",
        website: "#",
        aoi: "Pharmacology",
        education: "M. Pharmacy",
        re: "N/A",
        te: "0.5",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "Mansi, Dr. Nitish Bhatia, “Stress and Immune Diseases Role of Stress induced physiological and pathological changes resulting in Immune Dysfunction and consequent Disorders",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/Isfaq.jpg',name:'Mohd. Isfaq',position:'Assistant Professor',email:'Mohd.Isfaq@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=mohd.isfaq+sheikh+abbas",
        linkedin: "https://www.linkedin.com/in/mohd-isfaq-b22728a1",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Isoxazole derivatives",
        education: "M.pharm (Pharmaceutical Chemistry)",
        re: "1",
        te: "10",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/neha_bhamboo.jpg',name:'Ms. Neha Bhamboo',position:'Assistant Professor',email:'neha.bhamboo@mvn.edu.in', gs: "https://scholar.google.com/citations?user=QoJrPp8AAAAJ&hl=enORCID: ",
        linkedin: "https://www.linkedin.com/in/neha-bhamboo-804537271",
        orcid: "https://orcid.org/0000-0003-4312-0056",
        rg: "https://www.researchgate.net/profile/Neha-Bhamboo",
        website: "#",
        aoi: "Pharmaceutics",
        education: "Pursuing Ph.D (Pharmaceutics)",
        re: "N/A",
        te: "2.8",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/shivali_rahi.jpg',name:'Ms. Shivali Rahi',position:'Assistant Professor',email:'shivali.rahi@mvn.edu.in',gs: "https://scholar.google.com/citations?user=QSiIQacAAAAJ&hl=en",
        linkedin: "http://www.linkedin.com/in/shivali-rahi-5326a16a",
        orcid: "http://www.linkedin.com/in/shivali-rahi-5326a16a",
        rg: "https://www.researchgate.net/profile/Shivali-Rahi",
        website: "#",
        aoi: "Regulatory Affairs      ",
        education: "M.Pharmacy, PhD (Pursuing-Drug Regulatory Affairs)      ",
        re: "N/A",
        te: "3.2",
        iece: "0.4",
        articles:
          "<li>S. Rahi, V.Lather and A.Rana, “Artificial Intelligence: New breakthrough for Diabetes mellitus and Regulatory perspective”, International Journal of Pharmaceutical Sciences Review & Research, Vol.77, Issue 2, Page- 115-122, 2022.</li><li> S. Rahi., A.Rana, Prodrugs: A regulatory perspective, American Journal of Pharm Tech Research, Vol. 12, Issue 6, 2022.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "<li>Certificate of Registration for UK Design has been granted for “AI enabled device for early detection of cardiac diseases”, Publication date- 26th Oct 2023, Design number- 6319794.  </li>",
        membership:
          "<li>Lifetime member of Indian Pharmacy Graduates Association</li><li> Lifetime member of the Association of Young Pharma Professionals (TAYPP)</li>",
        aa1: "GOLD MEDALIST for securing I Position in M.Pharmacy  (DRA) program in the session 2018-2020 conducted by Pt. B.D Sharma University of Health Sciences, Rohtak, Haryana.",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/neha_dagar.jpg',name:'Neha Dagar',position:'Assistant Professor',email:'neha.dagar@mvn.edu.in',gs: "#",
        linkedin: "https://www.linkedin.com/in/neha-dager-7945b629a?",
        orcid: "https://orcid.org/0009-0004-4616-4032",
        rg: "https://www.researchgate.net/profile/Miss-Dagar",
        website: "#",
        aoi: "Pharmaceutics",
        education: "Masters in Pharmaceutics",
        re: "N/A",
        te: "0.5",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "<li>Trans-decorated chitosan-ethylcellulose nanoparticles for controlled & targeted delivery of Myricetin for Diabetes Mellitus</li>",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/parshant_kumar.jpg',name:'Parshant Kumar',position:'Assistant Professor',email:'parshant.kumar@mvn.edu.in',gs: "https://scholar.google.co.in/citations?hl=en&user=2iJMkNAAAAAJ      ",
        linkedin: "https://www.linkedin.com/in/prashant-vashisht-826103127  ",
        orcid: "https://orcid.org/0009-0006-6351-6399      ",
        rg: "https://www.researchgate.net/profile/Parshant-Kumar-8    ",
        website: "#",
        aoi: "Pharmacology      ",
        education: "M. Pharmacy      ",
        re: "N/A",
        te: "0.5",
        iece: "N/A",
        articles:
          "<li> Prashant Kumar, MayankKulshreshtha, “A comprehensive Review on Peptic Ulcer” Current Molecular Pharmacology, Bentham Science (Under Communication in 2023).</li><li>Dubey D, Murti Y, Wal P, Ved A, Kumar P, Sharma AK, Dwivedi H, Singh A, Singh MP, Kulshreshtha M. Formulation and evaluation of Chinese eucalyptus oil gel by using different gelling agents as an antimicrobial agent. Pharmacological Research-Modern Chinese Medicine. 2023 Dec 1;9:100333</li>        ",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/pushpika_jain.jpg',name:'Pushpika Jain',position:'Assistant Professor',email:'pushpika.jain@mvn.edu.in', gs: "https://scholar.google.com/citations?user=c9Rp1XcAAAAJ&hl=en&authuser=1",
        linkedin: "https://www.linkedin.com/in/pushpika-jain-4aa66b233/",
        orcid: "https://orcid.org/0009-0003-1634-7199?lang=en",
        rg: "https://www.researchgate.net/profile/Pushpika-Jain",
        website: "#",
        aoi: "Pharmaceutics",
        education: "M. Pharmacy",
        re: "N/A",
        te: "0.5",
        iece: "N/A",
        articles:
          "<li>Pushpika Jain, Juhi Mishra, Dr. Dhruv Kumar Nishad : “ Floating Oral Insitu Gel: Novel       Drug Delivery System For Protection against Radiation induced Gastritis</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/sanjeev_kumar.jpg',name:'Sanjeev Kumar',position:'Assistant Professor',email:' sanjeev.kumar@mvn.edu.in', gs: "https://scholar.google.com/citations?user=sujlDVoAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/snjvkr/",
        orcid: "https://orcid.org/0000-0003-4718-5519      ",
        rg: "https://www.researchgate.net/profile/Sanjeev-Kumar-331",
        website: "#",
        aoi: "Neurobehavioral Pharmacology",
        education: "M. Pharm. (Pharmacology)      ",
        re: "N/A",
        te: "15",
        iece: "2",
        articles:
          "<li>Kumar S, Mahaseth RK, Tiwari M, Sehgal R, Rajora P, Mathur R. Interaction of aqueous leaf extract of Aegle marmelos (L.) Corr. with cholinergic, serotonergic and adrenergic receptors: an ex vivo study. Indian J Pharmacol. 2015 Jan-Feb;47(1):109-13. doi: 10.4103/0253-7613.150374. PMID: 25821322; PMCID: PMC4375803.</li><li>Mahaseth RK, Kumar S, Dutta S, Sehgal R, Rajora P, Mathur R. Pharmacodynamic study of interaction of aqueous leaf extract of Psidium guajava Linn. (Myrtaceae) with receptor systems using isolated tissue preparations. Indian J Pharm Sci. 2015 Jul-Aug;77(4):493-9. doi: 10.4103/0250-474x.164778. PMID: 26664068; PMCID: PMC4649779.</li><li>Kumar S, Kumar S, Garg A, Sethy S, Gulati P. Raspberry ketone: an emerging molecule for treating depression and associated symptoms - molecular docking and pharmacokinetics studies. Intern J Health Sci. 2022 195-208. doi:10.53730/ijhs.v6n7.10811. </li><li>Kumar N, Mohd K, Mittal M, Kumar S, Dharmender An overview of antidepression model. Intern J Pharm and Pharmaceu Res 2020 19 (3), 134-149</li>        ",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "https://search.ipindia.gov.in/DesignQRStatus/PDF%20_Viewer.aspx?AppNo=MzY0NjI0LTAwMQ==&CNo=MTU4NDgx",
        membership:
          "<li>Indian Pharmacy Graduates’ Association since 2018</li><li> The International Society for Pharmacoeconomics and Outcomes Research since 2013</li>        ",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/Sudhir.jpg',name:'Sudhir Kaushik',position:'Assistant Professor',email:'sudhir.kaushik@mvn.edu.in', gs: "https://scholar.google.co.in/citations?view_op=new_articles&hl=en&imq=sudhir+kaushik",
        linkedin: "http://www.linkedin.com/in/sudhir-kaushik-941b176a/",
        orcid: "https://orcid.org/0009-0001-7423-4657",
        rg: "#",
        website: "#",
        aoi: "In-Silico Molecular modeling methods for identification of novel Anti-Diabetic Agents",
        education: "Pursuing PhD (Pharmaceutical Sciences)",
        re: "N/A",
        te: "15",
        iece: "N/A",
        articles:
          "<li>S.Kaushik, “ RP-HPLC Method Development and Validation for Estimation of an Antiviral Drug Molnupiravir Bulk and capsules, Journal of Physical Sciences, Engineering and Technology, Vol 14, Issue 4, Dec 2022.</li><li>S.Kaushik, “ Development and Validation of RP-HPTLC Method for estimation of Prochlorperazine edisylate using DOE Approach,  Journal of Physical Sciences, Engineering and Technology,  Vol no 14, Issue 4 Dec 2022</li><li>S.Kaushik, “ Qualitative and Quantitative Phytochemical Investigation of Trapa Bispinosa Roxb. Leaves Extract, Journal of Physical Sciences, Engineering and Technology, Vol No 14, Oct-Dec 2022.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "Life membership of Indian Pharmacy Graduates’ Association with Membership no LM-3377",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/vikas_kumar.jpg',name:'Vikas Sharma',position:'Assistant Professor',email:'vikas.sharma@mvn.edu.in', gs: "#",
        linkedin: "https://www.linkedin.com/in/vikassharma81",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Cardiac Biomarker",
        education: "M.pharma( Pharmacy Practice)",
        re: "1",
        te: "N/A",
        iece: "0.8",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "<li>Graduate Pharmacy Aptitude Test (GPAT) by National Testing Agency (NTA) India, 2021.</li><li> National Institute of Pharmaceutical Education and Research Joint Entrance Exam (NIPER- JEE) 2021– 2023.</li>",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/yogesh_vashisht.jpg',name:'Yogesh Vashisht',position:'Lecturer',email:'yogesh.sops@mvn.edu.in',gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Yogesh+Vashisth%23",
        linkedin: "https://www.linkedin.com/in/yogesh-vashisth",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Microspheres of Anti-hypertensive Drug",
        education: "M.Pharmacy (Pharmaceutics)",
        re: "1",
        te: "N/A",
        iece: "0.3",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "Life time membership in Indian pharmacy graduates association (IPGA) [LM-7784].",
        aa1: "Poster presentation on topic “Formulation and Evaluation of microspheres of an Anti-hypertensive agent” in NIET Institute, Noida.",
        aa2: "Poster Presentation on topic “development of microspheres for biomedical Applications” in MMU University, Ambala",
        aa3: "Poster Presentation on topic “Formulation and evaluation of sustained release microspheres” in SGT University, gurugram.",
        aa4: "Participated as Volunteer in 1st National conference in K.R. Mangalam University, gurugram.",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/hemlata_rawat.jpg',name:'Hemlata Rawat',position:'Lecturer',email:'hemlata.rawat@mvn.edu.in', gs: "https://scholar.google.com/citations?user=tDBaQOMAAAAJ&hl=en",
        linkedin: "www.linkedin.com/in/HemlataRawat",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Herbal Research in Pharmaceutics",
        education: "Bachelor’s in pharmacy",
        re: "N/A",
        te: "N/A",
        iece: "0.1",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "Participate in COVID-19General Awareness Quiz”.",
        aa2: "1st position in Hand Made poster presentation organized by MVN UNIVERSITY.",
        aa3: "Participate in ‘The Art of Writing Chemistry in Electronic Notebooks’",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOPS/faculty/manoj_sharma.jpg',name:'Manoj Sharma',position:'Lecturer',email:'manoj.sharma@mvn.edu.in', gs: "#",
        linkedin: " https://www.linkedin.com/in/manoj-sharma-389a56206",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Preclinical Studies",
        education: "B. Pharmacy",
        re: "N/A",
        te: "1",
        iece: "0.5",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          " Life Membership of IPGA",
        aa1: "Appreciation of digital Literacy by National Institutes of Electronics & Information Technology (NIELT)",
        aa2: "Certification In Handling of UV visible spectrophotometer by MVN University, Palwal, India",
        aa3: "Qualified GPAT Conducted by NTA & AICTE",
        aa4: "",
        aa5: "",
        aa6: "",},

    ]
return(
    <Grid container spacing={2} sx={{margin:'10px 0px'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainTitle title='School of Pharmaceutical Sciences'/>
        </Grid>
        {facultyList?.map((fac)=>(
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <FacultyCard faculty={fac} />
        </Grid>
        ))}
    </Grid>
)
}
export default FacultyList;