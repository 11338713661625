import React from "react";
import { Grid } from "@mui/material";
import "../../assets/css/faculty_detail.css";
import { useLocation } from "react-router-dom";

const FacultyDetail = () => {
  const location=useLocation()
  console.log('location::::::::::::',location)
  let faculty=location?.state
  return (
    <Grid container spacing={2} style={{ margin: "10px 0px" }}>
    
      <div class="container">
        <div class="team-single">
          <div class="row">
            <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
              <div class="team-single-img" style={{textAlign:'center'}}>
                <img src={faculty.img} alt="" />
              </div>
              <div class="bg-light-gray padding-30px-all md-padding-25px-all sm-padding-20px-all text-center">
                <h2>{faculty.name}</h2>
                <span>{faculty.position}</span>
                <div class="margin-20px-top team-single-icons">
                  <ul class="no-margin">
                    <li>
                      <a href={faculty.gs}>
                        <i class="fab fa-google-scholar"></i>
                      </a>
                    </li>
                    <li>
                      <a href={faculty.linkedin}>
                        <i class="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={faculty.orcid}>
                        <i class="fab fa-orcid"></i>
                      </a>
                    </li>
                    <li>
                      <a href={faculty.rg}>
                        <i class="fab fa-researchgate"></i>
                      </a>
                    </li>
                    <li>
                      <a href={faculty.website}>
                        <i class="fas fa-globe"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-md-7">
              <div class="team-single-text padding-50px-left sm-no-padding-left">
                <div class="member_designation">
                  <h3>{faculty.aoi}</h3>
                  <span>{faculty.education}</span>
                </div>
                <div class="mb-5 wow fadeIn">
                  <div class="row mt-n4">
                    <div class="col-sm-6 col-xl-4 mt-4">
                      <div class="card text-center border-0 rounded-3">
                        <div class="card-body">
                          <i class="ti-bookmark-alt icon-box medium rounded-3 mb-4"></i>
                          <h3 class="h5 mb-3">{faculty.re} years</h3>
                          <p class="mb-0">Research Experience</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4 mt-4">
                      <div class="card text-center border-0 rounded-3">
                        <div class="card-body">
                          <i class="ti-pencil-alt icon-box medium rounded-3 mb-4"></i>
                          <h3 class="h5 mb-3">{faculty.te} years</h3>
                          <p class="mb-0">Teaching Experience</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4 mt-4">
                      <div class="card text-center border-0 rounded-3">
                        <div class="card-body">
                          <i class="ti-medall-alt icon-box medium rounded-3 mb-4"></i>
                          <h3 class="h5 mb-3">{faculty.iece} years</h3>
                          <p class="mb-0">
                            Industrial Experience / Clinical Experience
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>

          
          

            <div class="col-lg-12 col-md-12">
              <div class="team-single-text padding-50px-left sm-no-padding-left">
                <div class="contact-info-section margin-40px-tb">
                
                  <ul class="list-style9 no-margin">

                  <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="far fa-newspaper text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                            About 
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p dangerouslySetInnerHTML={{ __html: faculty.about }}/>
                        </div>
                      </div>
                    </li>


                    <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="far fa-newspaper text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                            Research articles (Best Three)
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                          <p dangerouslySetInnerHTML={{ __html: faculty.articles }}/>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="far fa-book text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                            Book Published (All):
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                        <p dangerouslySetInnerHTML={{ __html: faculty.books }} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="far fa-file text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                            Book Chapters (Best Three):
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                        <p dangerouslySetInnerHTML={{ __html: faculty.chapters }} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="far fa-id-badge text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                            Patents Granted (Best Three):
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                        <p dangerouslySetInnerHTML={{ __html: faculty.patents }} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5 col-5">
                          <i class="fas fa-user-tie text-blue"></i>
                          <strong class="margin-10px-left xs-margin-four-left text-blue">
                            Membership of Professional Bodies
                          </strong>
                        </div>
                        <div class="col-md-7 col-7">
                        <p dangerouslySetInnerHTML={{ __html: faculty.membership }} />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="row ">
                  <div class="col-lg-12 member_desc">
                    <h4 class="text-blue">Achievement and Awards</h4>
                    <ol>
                      <li>{faculty.aa1}</li>
                      <li>{faculty.aa2}</li>
                      <li>{faculty.aa3}</li>
                      <li>{faculty.aa4}</li>
                      <li>{faculty.aa5}</li>
                      <li>{faculty.aa6}</li>
                      <li>{faculty.aa7}</li>
                      <li>{faculty.aa8}</li>
                      <li>{faculty.aa9}</li>

                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12"></div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default FacultyDetail;
